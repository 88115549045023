.footer {
    width: 100%;
    height: 4rem;
    background: rgb(40,40,40);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
    font-weight: 300;
    margin-top: auto;
}
/* so nothing in footer acts weird*/
.footer a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    cursor: text;
}