.popup {
    position: fixed;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    background-color: rgba(20,20,20);
    padding: 20px;
    border-radius: 10px;
    max-width: 33%;
    max-height: 80%;
    overflow: auto;
    text-align: center;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #FFF;
}

.project-img {
    max-width: 66%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.project-img-small {
    max-width: 50%;
    height: auto;
    border-radius: 5px;
}

.project-description {
    text-align: left;
    white-space: pre-wrap;
    color: #FFF;
}

@media screen and (max-width:480px)  {
    .popup-inner {
        margin-top: 1rem;
        max-width: 90%;
        max-height: 90%;
        padding: 15px;
    }

    .project-img {
        max-width: 100%;
    }

    .project-img-small {
        max-width: 80%;
    }

    .project-description {
        text-align: left;
    }
}
