.login-container {
    width: 100%;
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #2c2c2c;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.login-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: rgb(0,119,182);
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-form input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #2c2c2c;
    color: #e0e0e0;
}

.login-form input::placeholder {
    color: #999;
}

.login-form button {
    background-color: rgb(0,119,182);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.login-form button:hover {
    background-color: rgb(0,95,145);
}

@media screen and (max-width: 480px) {
    .login-container {
        padding: 1rem;
    }

    .login-title {
        font-size: 1.5rem;
    }

    .login-form input,
    .login-form button {
        font-size: 0.9rem;
    }
}