#portfolio {
    margin: 0 auto;
    padding: 0 1rem;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.portfolioTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.portfolioDescript {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.portfolioImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.portfolioImg {
    object-fit: cover;
    height: 20rem;
    margin: .5rem;
    cursor: pointer;
}

.moreProjects {
    margin: 3rem 0;
    padding: .5rem 2.5rem;
    background: white;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
}
.portfolioGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
}

.portfolioItem {
    margin: 0.5rem;
    text-align: center;
}

.portfolioImg {
    width: 100%;
    max-width: 20rem;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageTitle {
    margin-top: 0.2rem;
    font-size: 1rem;
    color: rgb(0,119,182);
}


@media screen and (max-width: 585px) {
    .portfolioImg {
        height: 48vw;
    }
}
