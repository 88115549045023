#blog {
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blogTitle {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: rgb(0,119,182);
}

.blogDescript {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    text-align: center;
}

.blogForm {
    width: 100%;
    max-width: 60rem;
    margin-bottom: 2rem;
    text-decoration-color: aliceblue;
}

.blogForm input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: rgb(0,119,182);
}

.blogForm .quill {
    margin-bottom: 1rem;
}
.blogForm .quill .ql-editor.ql-blank::before {
    color: #999; /* Same color as the input placeholder */
}

.blogForm button {
    background-color: rgb(0,119,182);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.blogForm button:hover {
    background-color: rgb(0,95,145);
}

.blog-post {
    width: 100%;
    max-width: 60rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.blog-post h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: rgb(0,119,182);
}

.post-meta {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.post-meta span {
    margin-right: 1rem;
}

.blog-post div {
    font-size: 1rem;
    line-height: 1.6;
}

.loadMoreBtn {
    background-color: rgb(0,119,182);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.loadMoreBtn:hover {
    background-color: rgb(0,95,145);
}

.loginPrompt {
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.loginPrompt a {
    color: rgb(0,119,182);
    text-decoration: none;
}

.loginPrompt a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 840px) {
    .blogTitle {
        font-size: 2.5rem;
    }

    .blogDescript {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .blogTitle {
        font-size: 2rem;
    }

    .blogDescript {
        font-size: 0.8rem;
    }

    .blog-post h3 {
        font-size: 1.2rem;
    }
}