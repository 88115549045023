.NavBar{
    background: rgb(20,20,20);
    height: 5rem;
    width: 100vw;
    max-width: 85rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    /*this justify creates space between the objects*/
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

}

.logo {
    /*allows proper ratio maintenance as scree changes width and height*/
    object-fit: cover;
    height: 5rem;
    width: 6rem;

}
.desktopMenuListItem:hover{
    color: rgb(0,119,182);
    padding-bottom: .5rem;
    border-bottom: 3px solid rgb(0,119,182);;
}
/*space out the navbar menu text*/
.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
    color: white;

}
.desktopMenuButton{
    background: rgb(0,119,182);
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    border-radius: 2rem;
    font-size: 1rem;
}
.desktopMenuIcon{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: .75rem;
}

.active{
    color: rgb(0,119,182);
    padding-bottom: .5rem;
    border-bottom: 3px solid  rgb(0,119,182);
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40,40,40);
    border: none;
    border-radius: 1rem;
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.listItem{
    color: white;
    padding: .5rem 3rem;
    margin: .25rem;
    background: rgb(30,30,30);
}

@media screen and (max-width: 720px){
    .mobMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
    .desktopMenuButton{
        display: none;
    }
}