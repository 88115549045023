#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    max-width: 50%;
    border-radius: inherit;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.welcome {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: rgb(0,119,182);
}

.introParagraph {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}

.introParagraph a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.introParagraph a:hover {
    text-decoration: underline;
    color: #0056b3;
}

@media screen and (max-width: 840px) {
    .bg {
        width: 60%;
    }
    .introContent {
        width: 40vw;
        font-size: 10vw;
    }
    .welcome {
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 480px) {
    .bg {
        width: 70%;
    }
    .introContent {
        width: 33vw;
    }
}
