#skills{
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

}

.skillTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.skillDescript{
    font-weight: 300;
    font-size: .9rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border: none;
    border-radius: .5rem;
    background: rgb(50,50,50);
}

.skillBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    margin-top: 1rem;
    overflow: visible;
}

.skillBarText{
    font-size: .8rem;
    font-weight: 200;

}

.skillBarText a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.skillBarText a:hover {
    text-decoration: underline;
    color: #0056b3;
}

@media screen and (max-width:720px)  {
    .skillBarText>p{
        font-size: 2vw;
    }
    
}

@media screen and (max-width:480px)  {
    .skillBarText>p{
        font-size: 3vw;
    }
    .skillBarImg{
        height: 2.25rem;
        width: 2.25rem;
    }
    .skillBarText>h2{
        font-size: 5vw;
    }
}
