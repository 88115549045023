#contact{
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 65rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactTitle{
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contactDescript{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;

}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;

}

.contactName, .contactEmail, .contactMsg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: .5rem;
    padding: .5rem 1rem;
    color: white;
    border: none;
    border-radius: .5rem;
    background: rgb(40,40,40);

}

.submitBtn{
    background: white;
    color: black;
    border: none;
    border-radius: .5rem;
    margin: 2rem;
    padding: .75rem 3.5rem;
    font-size: 1rem;

}

.extLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.extLink {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin: 0 .75rem;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.extLink img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.extLink:hover {
    transform: scale(1.1);
}